@import "../../variables.scss";
@import "../../mixins.scss";

.error {
  text-align: center;
  background-color: $white-text-color;
  color: $main-color;
  height: 100vh;

  &__title {
    font-size: 288px;
    margin: 80px auto;
    color: $color5;

    @media (max-width: 767.99px) {
      font-size: 96px;
      margin: 55px auto;
    }
  }

  &__txt {
    font-size: 36px;
    color: $color3;
    padding-bottom: 36px;

    @media (max-width: 767.99px) {
      font-size: 18px;
    }
  }

  &__link {
    color: $color5;
    font-family: Roboto;
    font-size: 18px;
    text-decoration-line: underline;
    margin: 100px auto;

    @media (max-width: 767.99px) {
      font-size: 14px;
      margin: 75px auto;
    }
  }
}

.edit-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  &-content {
    background-color: $white-text-color;
    width: 730px;
    max-width: calc(100vw - 20px);
    max-height: calc(100vh - 20px);
    overflow: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    border-radius: 10px;
    animation: scaleIn 300ms ease;
  }

  & .close {
    text-align: center;
    cursor: pointer;
    font-size: 20px;
    width: 30px;
    position: absolute;
    top: 30px;
    right: 36px;
  }
}

@keyframes scaleIn {
  0% {
    transform: scale(0.4);
  }
  100% {
    transform: scale(1);
  }
}

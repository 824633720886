@import "../../mixins.scss";
@import "../../variables.scss";

.header {
  color: $white-text-color;
  background-image: url("../../img/pages/livres.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  width: 100%;
  padding: 40px;

  @media (max-width: 1030px) {
    padding: 20px;
  }

  &__title {
    @include typo("dancing script", 80px);
    padding-top: 35vh;
    width: 50%;
    text-align: center;
    margin-left: 50%;

    @media (max-width: 1400px) {
      @include typo("dancing script", 60px);
    }

    @media (max-width: 1030px) {
      @include typo("dancing script", 50px);
    }

    @media (max-width: 540px) {
      @include typo("dancing script", 40px);
    }
  }

  &__cv-Link {
    position: absolute;
    background-color: $color6;
    border: 2px solid $color6;
    padding: 8px 12px;
    border-radius: 21px 3px;
    right: 50px;
    top: 100px;
    text-decoration: none;
    color: $white-text-color;
    font-style: oblique;
    @include typo ("roboto", 20px, 500);
    transition: 0.3s;

    @media (max-width: 1400px) {
      padding: 6px 8px;
      @include typo ("roboto", 14px, 400);
      right: 50px;
      top: 90px;
    }

    @media (max-width: 1030px) {
      padding: 6px 8px;
      @include typo ("roboto", 12px, 400);
      right: 30px;
      top: 55px;
    }

    &:hover {
      background-color: $main-color;
    }
  }

  &__subtitle {
    @include typo("roboto", 20px);
    @include flex(space-between, normal, row);

    @media (max-width: 1400px) {
      @include typo("roboto", 16px);
    }

    @media (max-width: 1030px) {
      @include typo("roboto", 12px);
    }

    .mobile-text {
      @media (max-width: 540px) {
        display: none;
      }
    }

    &2 {
      padding-right: 10px;
    }
  }

  $total-arrows: 3;
  $arrow-line-length: 35px;
  $arrow-line-width: 4px;
  $arrow-bounce-distance: 10px; // Distance de rebond

  // arrow animation + choose direction
  @mixin arrow-transitions($rot: 0deg) {
    transform: translate(-50%, -50%) rotateZ($rot);
  }

  // arrows
  .arrow-container {
    display: block;
    width: 60px;
    height: 60px;
    position: absolute;
    bottom: 2%;
    right: 2%;
    @include arrow-transitions(0deg);

    @media (max-width: 1030px) {
      width: 20px;
      height: 50px;
    }

    @media (max-width: 540px) {
      width: 8px;
      height: 50px;
      right: 8%;
    }

    &:hover {
      cursor: pointer;

      .arrow {
        top: 50%;

        &:before {
          @include arrow-transitions(-40deg);
        }

        &:after {
          @include arrow-transitions(40deg);
        }
      }
    }
  }

  .arrow {
    position: absolute;
    left: 50%;
    transition: all 0.4s ease;

    &:before,
    &:after {
      transition: all 0.4s ease;
      content: "";
      display: block;
      position: absolute;
      transform-origin: bottom right;
      background: #eaeaea;
      width: $arrow-line-width;
      height: $arrow-line-length;
      border-radius: 10px;
      transform: translate(-50%, -50%) rotateZ(-45deg);
    }

    &:after {
      transform-origin: bottom left;
      transform: translate(-50%, -50%) rotateZ(45deg);
    }

    @for $i from 1 through $total-arrows {
      &:nth-child(#{$i}) {
        opacity: 0.3 * $i;
        top: 15 + calc(100% * $i/5);
      }
    }
  }
}
@import "../../variables.scss";
@import "../../mixins.scss";

.about {
  width: 100%;
  min-height: 100vh;
  background-color: $main-color;
  padding-top: 120px;

  @media (max-width: 1030px) {
    padding-top: 90px;
  }

  &__title {
    padding: 36px;
    padding-bottom: 56px;
    color: $white-text-color;
    @include typo("dancing script", 3em, 400);
    text-align: center;
    @media (max-width: 890px) {
      font-size: 2em;
      padding: 20px;
      padding-top: 0;
    }
  }

  &__columns {
    width: 90%;
    margin: auto;
    @include flex(center, center, row);
    padding: 0 30px;
  }

  .skills {
    width: 35%;
    padding: 20px;
    text-align: center;

    @media (max-width: 1400px) {
      width: 45%;
      margin: auto;
    }
    @media (max-width: 1030px) {
      width: 70%;
    }
    @media (max-width: 890px) {
      width: 90%;
    }
    @media (max-width: 540px) {
      width: 100%;
    }

    &__title {
      @include typo("roboto", 2em, 200);
      color: $white-text-color;
      padding: 0 20px 36px 20px;

      @media (max-width: 890px) {
        font-size: 1.5em;
        padding: 20px;
        padding-top: 0;
      }
      @media (max-width: 540px) {
        font-size: 1.3em;
        padding: 15px;
        padding-top: 0;
      }
    }

    &__text {
      width: 100%;
      margin: auto;
      @include typo("roboto", 1em, 300);
      line-height: 1.3em;
      color: $white-text-color;
      padding-bottom: 36px;
      text-align: justify;
      @media (max-width: 540px) {
        font-size: 0.9em;
      }

      & p:nth-child(4) {
        padding-top: 24px;
      }

      & span {
        color: $color9;
      }
    }

    &__ctn {
      width: 50%;
      margin: auto;
      padding: 30px 0;
      padding-bottom: 60px;
      height: auto;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      align-items: center;
      gap: 40px;
      @media (max-width: 1400px) {
        width: 60%;
      }

      @media (max-width: 1030px) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @media (max-width: 890px) {
        gap: 20px;
        // width: 50%;
      }
      @media (max-width: 540px) {
        grid-template-columns: 1fr 1fr;
        width: 70%;
      }
    }
  }

  .portrait {
    width: 30%;
    padding: 20px;
    padding-top: 40px;
    text-align: right;
    border-radius: 10px;

    @media (max-width: 1400px) {
      width: 40%;
      text-align: center;
    }
    @media (max-width: 1030px) {
      display: none;
    }

    img {
      width: 75%;

      margin: auto;
      height: auto;
      border-radius: 10px;
      object-fit: cover;
      opacity: 0.8;
    }
  }
}

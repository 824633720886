@import "../../variables.scss";
@import "../../mixins.scss";

.edit-skill-form-wrapper {
  width: 80%;
  margin: auto;
  background-color: $color2;
  border-radius: 10px;
  text-align: center;
  padding: 20px;

  @media only screen and (max-width: 800px) {
    border-radius: 0 0 10px 10px;
  }

  .form-item {
    position: relative;

    label,
    input,
    textarea {
      font-family: "roboto";
    }

    label {
      position: absolute;
      top: 10px;
      left: 12%;
      color: $color7;
      font-size: clamp(14px, 1.5vw, 18px);
      pointer-events: none;
      user-select: none;
    }

    input,
    textarea {
      width: 80%;
      outline: 0;
      border: 1px solid $color5;
      border-radius: 4px;
      margin-bottom: 20px;
      padding: 12px;
      font-size: clamp(15px, 1.5vw, 18px);
    }

    input:focus + label,
    input:valid + label,
    textarea:focus + label,
    textarea:valid + label {
      font-size: clamp(13px, 1.3vw, 16px);
      color: $main-color;
      top: -20px;
      transition: all 0.225s ease;
    }
  }

  .submit-btn,
  .delete-btn {
    background-color: $color7;
    filter: drop-shadow(2px 2px 3px #0003);
    color: $white-text-color;
    font-family: "roboto";
    font-size: clamp(16px, 1.6vw, 18px);
    display: block;
    padding: 12px 20px;
    margin: 8px auto;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    transition: 0.2s;

    &:hover {
      transform: scale(1.1, 1.1);
    }

    &:active {
      transform: scale(1.1, 1.1);
      filter: sepia(0.5);
    }
  }
}

@import "../../variables.scss";
@import "../../mixins.scss";

.techCard {
  width: 150px;
  height: 130px;
  background-color: $color9;
  opacity: 0.9;
  padding: 10px;
  border-radius: 20px;
  transition: transform 0.3s ease-in-out;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.6);
  text-align: center;
  margin: auto;
  @media (max-width: 890px) {
    width: 100px;
    height: 115px;
  }
  p {
    font-size: 20px;
    margin-top: 5px;
    color: $main-color;
    opacity: 0.9;
    @media (max-width: 890px) {
      font-size: 16px;
    }
  }

  img {
    width: 80px;
    height: 80px;
    opacity: 0.9;
    @media (max-width: 890px) {
      width: 50px;
      height: 50px;
    }
  }

  &:hover {
    transform: scale(1.1);
    opacity: 1;
    // transform: translatey(-3px);
    // box-shadow: 10px 15px 10px rgba(0, 0, 0, 0.8);
  }
}

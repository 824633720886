@mixin typo ($font-family, $font-size, $font-weight:400) {
    font-family: $font-family;
    font-size: $font-size;
    font-weight: $font-weight;
};

@mixin flex($justifyContent, $alignItems, $flexDirection) {
    display: flex;
    justify-content: $justifyContent;
    align-items: $alignItems;
    flex-direction: $flexDirection;
    flex-wrap: wrap;
  };
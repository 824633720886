@import "../../variables.scss";
@import "../../mixins.scss";

.admin-projects {
  @include flex(normal, normal, row);
  padding-top: 40px;
  width: 100%;
  height: 100%;
  background-color: $white-text-color;

  @media (max-width: 1030px) {
    padding-top: 90px;
  }

  & .sidebar {
    width: 18vw;
    padding-top: 15vh;
    background-color: $color7;
    margin-top: -40px;

    &__btn {
      width: 75%;
      background-color: $color5;
      filter: drop-shadow(2px 2px 3px #0003);
      color: $white-text-color;
      font-family: "roboto";
      font-size: clamp(16px, 1.6vw, 18px);
      display: block;
      padding: 12px 20px;
      margin: 20px auto;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      user-select: none;
      transition: 0.2s;
    }

    &__title {
      @include typo("dancing script", 2.3em, 400);
      text-align: center;
      margin: auto;
      margin-bottom: 24px;
      padding-bottom: 36px;
      color: $white-text-color;
      width: 85%;
    }
  }

  & .main-content {
    width: 80vw;
    margin: auto;
    padding-bottom: 60px;
  }

  & .projects__part {
    padding-bottom: 36px;

    &-container {
      width: 95%;
      background-color: $main-color;
      border-radius: 10px;
      margin: auto;
      padding: 48px;
      margin-top: 32px;
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr 1fr;

      @media (max-width: 1400px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 1030px) {
        grid-template-columns: 1fr;
      }

      @media (max-width: 540px) {
        padding: 30px;
      }
    }
  }
}
@import "../../variables.scss";
@import "../../mixins.scss";

.projects {
  padding-top: 120px;
  padding-bottom: 60px;
  width: 100%;
  height: 100%;
  background-color: $white-text-color;
  @media (max-width: 1030px) {
    padding-top: 90px;
  }

  &__title {
    @include typo("dancing script", 3em, 400);
    text-align: center;
  }
  &-container {
    width: 80%;
    background-color: $main-color;
    border-radius: 10px;
    margin: auto;
    padding: 48px;
    margin-top: 32px;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    @media (max-width: 1400px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 1030px) {
      grid-template-columns: 1fr;
    }
    @media (max-width: 540px) {
      padding: 30px;
    }
  }
}

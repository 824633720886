// Couleurs

$main-color: rgb(29, 31, 32);
$white-text-color: rgb(234, 234, 234);
$color1 : #232120;
$color2: rgb(192, 181, 173);
$color3:#ad8f6e;
$color4:#674b38;
$color5:#5d6e6d;
$color6:rgb(69, 86, 78);
$color7:rgb(122, 141, 131);
$color8:#649494;
$color9: #d4c19c


@import "../../variables.scss";
@import "../../mixins.scss";

.contact {
  width: clamp(100px, 90%, 1000px);
  margin: 80px 50px;
  display: flex;
  flex-wrap: wrap;
  margin: auto;

  @media only screen and (max-width: 400px) {
    width: 95%;
  }

  .contact-links,
  .contact-form-wrapper {
    width: 50%;
    padding: 8% 5% 10% 5%;

    @media only screen and (max-width: 800px) {
      width: 100%;
    }
  }

  &-links {
    background-color: $main-color;
    border-radius: 10px 0 0 10px;

    &-title {
      @include typo("dancing script", clamp(30px, 6vw, 60px), 200);
      color: $white-text-color;
      letter-spacing: 2px;
      text-align: center;
      transform: scale(0.95, 1);

      @media only screen and (max-width: 800px) {
        font-size: clamp(40px, 10vw, 60px);
      }

      @media only screen and (max-width: 400px) {
        font-size: clamp(30px, 12vw, 60px);
      }
    }

    .links {
      @include flex(center, normal, row);
      padding-top: 50px;

      @media only screen and (max-width: 400px) {
        padding-top: 30px;
      }

      .link {
        margin: 10px;
        cursor: pointer;
        padding: 0 8px;

        img {
          width: 40px;
          height: 40px;
          filter: hue-rotate(220deg) drop-shadow(2px 4px 4px $main-color);
          transition: 0.2s;
          user-select: none;
          background-color: $white-text-color;
          border-radius: 30px;
          opacity: 0.8;

          @media only screen and (max-width: 400px) {
            width: 38px;
            height: 38px;
          }
        }

        img:hover {
          transform: scale(1.1, 1.1);
        }

        img:active {
          transform: scale(1.1, 1.1);
          filter: hue-rotate(220deg) drop-shadow(2px 4px 4px #222) sepia(0.3);
        }
      }
    }

    @media only screen and (max-width: 800px) {
      border-radius: 10px 10px 0 0;
    }
  }

  &-form-wrapper {
    background-color: $color2;
    border-radius: 0 10px 10px 0;

    @media only screen and (max-width: 800px) {
      border-radius: 0 0 10px 10px;
    }

    .form-item {
      position: relative;

      label,
      input,
      textarea {
        font-family: "roboto";
      }

      label {
        position: absolute;
        top: 10px;
        left: 2%;
        color: $color7;
        font-size: clamp(14px, 1.5vw, 18px);
        pointer-events: none;
        user-select: none;
      }

      input,
      textarea {
        width: 100%;
        outline: 0;
        border: 1px solid $color5;
        border-radius: 4px;
        margin-bottom: 20px;
        padding: 12px;
        font-size: clamp(15px, 1.5vw, 18px);
      }

      input:focus + label,
      input:valid + label,
      textarea:focus + label,
      textarea:valid + label {
        font-size: clamp(13px, 1.3vw, 16px);
        color: $main-color;
        top: -20px;
        transition: all 0.225s ease;
      }
    }

    .submit-btn {
      background-color: $color7;
      filter: drop-shadow(2px 2px 3px #0003);
      color: $white-text-color;
      font-family: "roboto";
      font-size: clamp(16px, 1.6vw, 18px);
      display: block;
      padding: 12px 20px;
      margin: 2px auto;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      user-select: none;
      transition: 0.2s;

      &:hover {
        transform: scale(1.1, 1.1);
      }

      &:active {
        transform: scale(1.1, 1.1);
        filter: sepia(0.5);
      }
    }
  }
}
